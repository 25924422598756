export const VolumeBar = ({ value, onVolumeUpdated }: { value:number, onVolumeUpdated: (newValue: number) => void }) => {

  const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

    event.stopPropagation();
    event.preventDefault();

    const newValue = parseFloat(event.target.value) / 100;
    onVolumeUpdated(newValue);
  }

  return <input 
  type="range" 
  onChange={onValueChanged} 
  style={{accentColor: "white"}}
  value={value}
  onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
  />
}