export const randomIntervals = [
  { textualValue: "1/H", value: 3600, title: "Play once every hour." },
  { textualValue: "2/H", value: 1800, title: "Play twice every hour." },
  { textualValue: "3/H", value: 1200, title: "Play three times every hour." },
  { textualValue: "4/H", value: 900, title: "Play four times every hour." },
  { textualValue: "5/H", value: 720, title: "Play five times every hour." },
  { textualValue: "6/H", value: 600, title: "Play six times every hour." },
  { textualValue: "7/H", value: 514, title: "Play seven times every hour." },
  { textualValue: "8/H", value: 450, title: "Play eight times every hour." },
  { textualValue: "9/H", value: 400, title: "Play nine times every hour." },
  { textualValue: "10/H", value: 360, title: "Play ten times every hour." },
  { textualValue: "15/H", value: 240, title: "Play fifteen times every hour." },
  { textualValue: "20/H", value: 180, title: "Play twenty times every hour." },
  { textualValue: "30/H", value: 120, title: "Play thirty times every hour." },
  { textualValue: "45/H", value: 80, title: "Play forty-five times every hour." },
  { textualValue: "1/M", value: 60, title: "Play once every minute." },
  { textualValue: "2/M", value: 30, title: "Play twice every minute." },
  { textualValue: "3/M", value: 20, title: "Play three times every minute." },
  { textualValue: "4/M", value: 15, title: "Play four times every minute." },
  { textualValue: "5/M", value: 12, title: "Play five times every minute." },
  { textualValue: "6/M", value: 10, title: "Play six times every minute." },
  { textualValue: "7/M", value: 9, title: "Play seven times every minute." },
  { textualValue: "8/M", value: 8, title: "Play eight times every minute." },
  { textualValue: "9/M", value: 7, title: "Play nine times every minute." },
  { textualValue: "10/M", value: 6, title: "Play ten times every minute." },
]

export class Sound {

  private audio!: HTMLAudioElement;
  private userInteracted = false;
  isRandom = false;
  intervalValue = 0;
  private interval: NodeJS.Timer | undefined = undefined;

  constructor(
    readonly id: string,
    readonly name: string,
    readonly src: string[],
    volume = 0,
    intervalValue = 0
  ) {
    this.nextSound();
    this.setVolume(volume, true);

    if (intervalValue > 0) {
      this.setRandom(intervalValue);
    }
  }

  private nextSound() {
    const currentVolume = !!this.audio ? this.audio.volume : 0;
    const newAudio = new Audio(`/audio/${this.src[Math.floor(Math.random() * this.src.length)]}`);
    newAudio.onended = this.nextSound.bind(this);
    newAudio.volume = currentVolume;
    if (this.userInteracted && !this.isRandom) {
      newAudio.play();
    }
    this.audio = newAudio;
  }

  setRandom(interval: number) {
    this.isRandom = true;
    this.intervalValue = interval;

    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.isActive) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }

    this.interval = setInterval(() => {
      if (!this.isActive) {
        this.audio.play();
      } else {
      }
    }, interval * 1000);
  }

  stopRandom() {
    this.isRandom = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setVolume(value: number, isInitial = false) {
    this.audio.volume = value;
    if (this.volume > 0 && this.audio.paused && !isInitial) {
      this.userInteracted = true;
      if (this.isRandom) {
        this.setRandom(this.intervalValue);
      } else {
        this.audio.play();
      }
    }
  }

  get volume() {
    return this.audio.volume;
  }

  get isActive() {
    return !this.audio.paused && this.volume > 0;
  }
}