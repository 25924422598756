import {
  faAmbulance,
  faAngry,
  faBell,
  faBoltLightning,
  faBomb,
  faBong,
  faBridgeWater,
  faBroadcastTower,
  faBug,
  faBuilding,
  faBus,
  faCat,
  faChild,
  faCloudRain,
  faComments,
  faComputer,
  faComputerMouse,
  faDice,
  faDog,
  faDove,
  faDungeon,
  faFeather,
  faFireAlt,
  faFish,
  faFloppyDisk,
  faFootballBall,
  faGlobe,
  faGun,
  faHandcuffs,
  faHardDrive,
  faHeart,
  faHeartPulse,
  faHelicopter,
  faJetFighter,
  faKeyboard,
  faLightbulb,
  faMosque,
  faPager,
  faPause,
  faPhone,
  faPlaneArrival,
  faPlaneUp,
  faPlay,
  faPray,
  faPrint,
  faRecordVinyl,
  faRotateLeft,
  faSatelliteDish,
  faScroll,
  faShare,
  faShip,
  faShuttleSpace,
  faSwimmer,
  faTowerBroadcast,
  faTrafficLight,
  faTrain,
  faVolumeHigh,
  faWater,
  faWaterLadder,
  faWind
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = (props: { icon: string }) => {

  const iconMapping = {
    "play": faPlay,
    "pause": faPause,
    "share": faShare,
    "reset": faRotateLeft,
    "random": faDice,
    "wind": faWind,
    "rain": faCloudRain,
    "thunder": faBoltLightning,
    "waterfall": faWater,
    "river": faBridgeWater,
    "creek": faWater,
    "waves": faWater,
    "underwater": faWaterLadder,
    "fire": faFireAlt,
    "traffic": faTrafficLight,
    "airport announcement": faPlaneArrival,
    "office": faBuilding,
    "typing": faKeyboard,
    "paper": faScroll,
    "phone": faPhone,
    "boat horn": faShip,
    "swimming": faSwimmer,
    "crowd talking": faComments,
    "children playing": faChild,
    "sports stadium": faFootballBall,
    "seagulls": faDove,
    "birds": faFeather,
    "crickets": faBug,
    "dog": faDog,
    "cat": faCat,
    "cat purr": faCat,
    "alarm": faBell,
    "air raid": faVolumeHigh,
    "gun fight": faGun,
    "explosions": faBomb,
    "police": faHandcuffs,
    "ambulance": faAmbulance,
    "muezzin": faMosque,
    "tibetan monks": faPray,
    "church bells": faBell,
    "singing bowl": faBong,
    "white noise": faWind,
    "vintage noise": faRecordVinyl,
    "prop engine": faHelicopter,
    "prop overhead": faPlaneUp,
    "jet overhead": faJetFighter,
    "angry crowd": faAngry,
    "distant radio": faBroadcastTower,
    "tuning radio": faSatelliteDish,
    "whale": faFish,
    "floppy drive": faFloppyDisk,
    "hard drive": faHardDrive,
    "hard drive 2": faHardDrive,
    "modem": faGlobe,
    "computer startup": faComputer,
    "mouse click": faComputerMouse,
    "printer": faPrint,
    "sonar": faTowerBroadcast,
    "beep": faPager,
    "heartbeat": faHeart,
    "heartbeat monitor": faHeartPulse,
    "vending machines": faBuilding,
    "spaceship": faShuttleSpace,
    "fluorescent light": faLightbulb,
    "dungeon": faDungeon,
    "diesel engine": faBus,
    "announcement bell": faBell,
    "school bell": faBell,
    "train passing": faTrain
  }


  // @ts-ignore
  const icon = iconMapping[props.icon.toLowerCase()];

  return <FontAwesomeIcon size={'2x'} icon={icon || faWind} />
}