import { useEffect, useState } from "react";
import "./ambient-sound.css";
import { VolumeBar } from "./volume-bar";
import { randomIntervals, Sound } from "../sound";
import { Icon } from "./icon";

const getRandomIntervalIndexByValue = (value: number) => {
  const foundIndex = randomIntervals.findIndex(interval => interval.value === value);
  return foundIndex === -1 ? 0 : foundIndex;
}

export const AmbientSound = ({ sound, muteClicked, filter }: { sound: Sound, muteClicked: number, filter: string }) => {


  const [volume, setVolume] = useState(sound.volume);
  const [previousVolume, setPreviousVolume] = useState(0.5);
  const [isRandom, setIsRandom] = useState(sound.isRandom);
  const [randomInterval, setRandomInterval] = useState(getRandomIntervalIndexByValue(sound.intervalValue));

  const filterMatches = sound.name.toLowerCase().includes(filter.toLowerCase());

  const className = `${volume > 0 ? "active" : ""} ${filterMatches ? "" : "hidden"}`;

  useEffect(() => {
    if (volume !== sound.volume) {
      sound.setVolume(volume);
    }
  }, [sound, volume]);

  useEffect(() => {
    if (isRandom) {
      sound.setRandom(randomIntervals[randomInterval].value);
    } else {
      sound.stopRandom();
    }
  }, [isRandom, randomInterval, sound]);

  useEffect(() => {
    if (muteClicked % 2 === 0) {
      setVolume(0);
      setPreviousVolume(volume)
    } else if (volume === 0) {
      setVolume(previousVolume);
      setPreviousVolume(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muteClicked])

  const onManualVolumeChange = (newValue: number) => {
    if (newValue < 0.05) {
      newValue = 0;
    }
    setVolume(newValue);
  };

  const toggleMute = () => {
    if (volume > 0) {
      setPreviousVolume(volume);
      setVolume(0);
    } else {
      setVolume(previousVolume);
      setPreviousVolume(0);
    }
  }

  return <section className={`ambient-sound ${className}`} >
    <button onClick={toggleMute}>
      <Icon icon={sound.name} />
      {sound.name}
    </button>
    <VolumeBar
      value={volume * 100}
      onVolumeUpdated={onManualVolumeChange}
    />
    <div className="random-control">
      <button
        onClick={() => setIsRandom(!isRandom)}
        className={isRandom ? "random-active" : ""}
        title="Play this sound randomly."
      >
        {isRandom ? "☑" : "☐"} Random
      </button>
      <button
        onClick={() => {
          if (randomInterval === randomIntervals.length - 1) {
            setRandomInterval(0);
          } else {
            setRandomInterval(randomInterval + 1);
          }
        }}
        title={randomIntervals[randomInterval].title}
      >
        {randomIntervals[randomInterval].textualValue}
      </button>
    </div>
  </section>
}