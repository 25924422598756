import { ConfigurationEntry } from "./configuration-loader";
import { Sound } from "./sound";

export type Category = {
  category: string;
  sounds: Sound[];
}

const rawSoundsByCategory = [
  {
    category: "Natural Environments",
    sounds: [
      {
        "id": "1a",
        "name": "Rain",
        "src": ["rain-01.mp3"]
      },
      {
        "id": "1b",
        "name": "Wind",
        "src": ["wind.mp3"]
      },
      {
        "id": "1c",
        "name": "Thunder",
        "src": ["thunder-01.mp3", "thunder-02.mp3", "thunder-03.mp3"]
      },
      {
        "id": "1d",
        "name": "Waterfall",
        "src": ["waterfall.mp3"]
      },
      {
        "id": "1e",
        "name": "River",
        "src": ["river.mp3"]
      },
      {
        "id": "1f",
        "name": "Creek",
        "src": ["creek.mp3"]
      },
      {
        "id": "1g",
        "name": "Waves",
        "src": ["waves.mp3"]
      },
      {
        "id": "1i",
        "name": "Underwater",
        "src": ["underwater.mp3"]
      },
      {
        "id": "1h",
        "name": "Fire",
        "src": ["fire.mp3"]
      }
    ]
  },
  {
    category: "Urban Environments",
    sounds: [
      {
        "id": "2a",
        "name": "Traffic",
        "src": ["traffic.mp3"]
      },
      {
        "id": "2b",
        "name": "Airport announcement",
        "src": ["airport-announcement.mp3"]
      },
      {
        "id": "2c",
        "name": "Office",
        "src": ["office.mp3"]
      },

      {
        "id": "2e",
        "name": "Paper",
        "src": ["paper.mp3"]
      },
      {
        "id": "2f",
        "name": "Phone",
        "src": ["phone.mp3"]
      },
      {
        "id": "2g",
        "name": "School Bell",
        "src":[
          "school-bell-1.mp3",
          "school-bell-2.mp3",
          "school-bell-3.mp3",
        ]
      }
    ]
  },
  {

    category: "Human Activities",
    sounds: [
      {
        "id": "3a",
        "name": "Boat Horn",
        "src": ["boat-horn-01.mp3", "boat-horn-02.mp3", "boat-horn-03.mp3", "boat-horn-04.mp3"]
      },
      {
        "id": "3b",
        "name": "Swimming",
        "src": ["soft-swimming.mp3"]
      },
      {
        "id": "3x", // Watch out, this was a duplicate.
        "name": "Crowd Talking",
        "src": ["crowd.mp3"]
      },
      {
        "id": "3d",
        "name": "Children playing",
        "src": ["children-playing.mp3"]
      },
      {
        "id": "3c",
        "name": "Sports stadium",
        "src": [
          "football-stadium-1.mp3",
          "football-stadium-2.mp3",
          "football-stadium-3.mp3",
        ]
      },
      {
        "id": "3e",
        "name": "Diesel Engine",
        "src": ["diesel-engine.mp3"]
      },
      {
        "id": "3f",
        "name": "Train Passing",
        "src": [
          "train-passing-1.mp3",
          "train-passing-2.mp3",
          "train-passing-3.mp3",
          "train-passing-4.mp3",
          "train-passing-5.mp3",
        ]
      },

    ]
  },
  {
    category: "Animals",
    sounds: [
      {
        "id": "4a",
        "name": "Seagulls",
        "src": ["seagulls.mp3"]
      },
      {
        "id": "4b",
        "name": "Birds",
        "src": ["birds-1.mp3", "birds-2.mp3", "birds-3.mp3"]
      },
      {
        "id": "4c",
        "name": "Crickets",
        "src": ["crickets.mp3"]
      },
      {
        "id": "4d",
        "name": "Dog",
        "src": ["dog-01.mp3", "dog-02.mp3", "dog-03.mp3", "dog-04.mp3", "dog-05.mp3"]
      },
      {
        "id": "4e",
        "name": "Cat",
        "src": ["cat-01.mp3", "cat-02.mp3"]
      },
      {
        "id": "4f",
        "name": "Cat Purr",
        "src": ["cat-purr-01.mp3", "cat-purr-02.mp3"]
      },
      {
        "id": "4g",
        "name": "Whale",
        "src": [
          "whale-1.mp3",
          "whale-2.mp3",
          "whale-3.mp3",
        ]
      }
    ]
  },
  {
    category: "Emergency Situations",
    sounds: [
      {
        "id": "5a",
        "name": "Alarm",
        "src": ["alarm.mp3"]
      },
      {
        "id": "5b",
        "name": "Air Raid",
        "src": ["air-raid.mp3"]
      },
      {
        "id": "5c",
        "name": "Gun Fight",
        "src": ["gunfight-02.mp3", "gunfight-03.mp3", "gunfight-04.mp3", "gunfight-05.mp3", "gunfight-01.mp3", "gunfight-06.mp3", "gunfight-09.mp3", "gunfight-10.mp3"]
      },
      {
        "id": "5d",
        "name": "Explosions",
        "src": ["explosion-01.mp3", "explosion-02.mp3", "explosion-03.mp3", "explosion-04.mp3", "explosion-05.mp3"]
      },
      {
        "id": "5e",
        "name": "Police",
        "src": ["police.mp3"]
      },
      {
        "id": "5f",
        "name": "Ambulance",
        "src": ["ambulance.mp3"]
      }
    ]
  },
  {
    category: "Religious",
    sounds: [
      {
        id: "r2",
        name: "Muezzin",
        src: ["muezzin-1.mp3"]
      },
      {
        id: "r3",
        name: "Tibetan Monks",
        src: ["tibetan-monks.mp3"]
      },
      {
        id: "r4",
        name: "Church Bells",
        src: [
          "church-bell-1.mp3",
          "church-bell-2.mp3",
          "church-bell-3.mp3",
          "church-bell-4.mp3"
        ]
      },
      {
        "id": "r5",
        "name": "Singing Bowl",
        "src": ["singing-bowl-1.mp3", "singing-bowl-2.mp3", "singing-bowl-3.mp3", "singing-bowl-4.mp3"]
      }
    ]
  },
  {
    category: "Computer Noises",
    sounds: [
      {
        "id": "c2",
        "name": "Floppy Drive",
        "src": ["floppy-1.mp3", "floppy-2.mp3"]
      },
      {
        "id": "c3",
        "name": "Hard Drive",
        "src": ["hard-drive.mp3"]
      },
      {
        "id": "c4",
        "name": "Hard Drive 2",
        "src": ["hard-drive-2.mp3"]
      },
      {
        "id": "c5",
        "name": "Modem",
        "src": ["modem-1.mp3"]
      },
      {
        "id": "c6",
        "name": "Computer Startup",
        "src": ["computer-start-up.mp3"]
      },
      {
        "id": "c7",
        "name": "Typing",
        "src": ["typing.mp3"]
      },
      {
        "id": "c8",
        "name": "Mouse Click",
        "src": [
          "mouse-click-1.mp3",
          "mouse-click-2.mp3",
          "mouse-click-3.mp3",
          "mouse-click-4.mp3"
        ]
      },
      {
        "id": "c9",
        "name": "Printer",
        "src": [
          "printer-1.mp3",
          "printer-2.mp3",
          "printer-3.mp3"
        ]
      }

    ]
  },
  {
    category: "Ambient",
    sounds: [
      {
        "id": "a1",
        "name": "White Noise",
        "src": ["white-noise.mp3"]
      },
      {
        "id": "a2",
        "name": "Vintage Noise",
        "src": ["vintage-noise.mp3"]
      },
      {
        "id": "a3",
        "name": "Vending Machines",
        "src": ["vending-machines.mp3"]
      },
      {
        "id": "a4",
        "name": "Spaceship",
        "src": ["spaceship.mp3"]
      },
      {
        "id": "a5",
        "name": "Fluorescent Light",
        "src": ["flourescentlight.mp3"]
      },
      {
        "id": "a6",
        "name": "Dungeon",
        "src": ["dungeon.mp3"]
      }
    ]
  },
  {
    category: "Miscellaneous",
    sounds: [
      {
        "id": "6c",
        "name": "Prop Engine",
        "src": ["prop-engine.mp3"]
      },
      {
        "id": "6d",
        "name": "Prop Overhead",
        "src": ["prop-overhead-01.mp3", "prop-overhead-02.mp3", "prop-overhead-03.mp3"]
      },
      {
        "id": "6e",
        "name": "Jet Overhead",
        "src": ["jet-overhead-01.mp3", "jet-overhead-02.mp3", "jet-overhead-03.mp3"]
      },
      {
        "id": "6f",
        "name": "Angry Crowd",
        "src": ["angry-crowd-01.mp3", "angry-crowd-02.mp3"]
      },
      {
        "id": "6g",
        "name": "Distant Radio",
        "src": ["distant-radio.mp3"]
      },
      {
        "id": "6h",
        "name": "Tuning Radio",
        "src": ["tuning-radio.mp3"]
      },
      {
        "id": "6i",
        "name": "Sonar",
        "src": [
          "sonar-1.mp3",
        ]
      },
      {
        "id": "6j",
        "name": "Beep",
        "src": [
          "beep-1.mp3",
          "beep-2.mp3",
          "beep-3.mp3",
        ]
      },
      {
        "id": "6k",
        "name": "Heartbeat",
        "src": ["heartbeat.mp3"]
      },
      {
        "id": "6l",
        "name": "Heartbeat monitor",
        "src": ["heart-beat-pulse.mp3"]
      },
      {
        "id": "6m",
        "name": "Announcement Bell",
        "src": ["announcement-bell.mp3"]
      }

    ]
  }
];



const getSounds = (config: ConfigurationEntry[]) => {
  const Sounds: Category[] = [];

  for (const category of rawSoundsByCategory) {
    const categorySounds = [];
    for (const sound of category.sounds) {
      if (config.find(c => Object.keys(c).includes(sound.id))) {
        // @ts-ignore
        const volume = config.find(c => Object.keys(c).includes(sound.id))?.[sound.id];

        const randomValue = config.find(c => Object.keys(c).includes(sound.id))?.random;

        if (randomValue) {
          categorySounds.push(new Sound(sound.id, sound.name, sound.src, volume, randomValue));
        } else {
          categorySounds.push(new Sound(sound.id, sound.name, sound.src, volume));
        }

      } else {
        categorySounds.push(new Sound(sound.id, sound.name, sound.src));
      }
    }
    Sounds.push({ category: category.category, sounds: categorySounds });
  }

  return Sounds;
}

export { getSounds };