import { useState } from 'react';
import './App.css';
import { AmbientSound } from './components/ambient-sound';
import { getSounds } from './sounds';
import { generateConfiguration, generateRandomConfiguration, getConfiguration } from './configuration-loader';
import { Icon } from './components/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

function App() {


  const [sounds] = useState(getSounds(getConfiguration()));
  const [muteClicked, setMuteClicked] = useState(0);

  const [filter, setFilter] = useState("");

  const onClickMuteAll = () => {
    setMuteClicked(muteClicked + 1);
  }

  const onClickShare = () => {
    const encodedConfiguration = generateConfiguration(sounds);
    const url = `${window.location.origin}?c=${encodedConfiguration}`;
    navigator.clipboard.writeText(url);
    alert(`Link copied to clipboard!`);
  }

  const onClickRandom = () => {
    const encodedConfiguration = generateRandomConfiguration(sounds);
    const url = `${window.location.origin}?c=${encodedConfiguration}`;
    window.location.href = url;
  }

  const onClickReset = () => {
    window.location.href = window.location.origin;
  }


  return <>


    <header>
      <h1>AMBIANIQ</h1>
      <h2>Enjoy complex soundscapes</h2>
    </header>

    <main>

      <div className="control-container">
        <button className="top-button mute-button" onClick={onClickMuteAll}>
          <Icon icon={muteClicked % 2 === 0 ? "play" : "pause"} />
          <p>{muteClicked % 2 === 0 ? "Play" : "Pause"}</p>
        </button>
        <button className="top-button share-button" onClick={onClickShare}>
          <Icon icon="share" />
          <p>Share</p>
        </button>
        <button className="top-button random-button" onClick={onClickRandom}>
          <Icon icon="random" />
          <p>Random</p>
        </button>
        <button className="top-button reset-button" onClick={onClickReset}>
          <Icon icon="reset" />
          <p>Reset</p>
        </button>
      </div>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        <button>
          <FontAwesomeIcon icon={faClose} onClick={() => setFilter("")} />
        </button>
      </div>

      {sounds.map(category => {
        return <section key={category.category}>
          <h2 className="category-title">{category.category}</h2>
          <div className="sound-container">
            {category.sounds.map(sound => <AmbientSound muteClicked={muteClicked} key={sound.name} sound={sound} filter={filter} />)}
          </div>
        </section>
      })}
    </main>

    <footer>
    </footer>
  </>
}

export default App;
