import { Category } from "./sounds";
import { randomIntervals } from "./sound";

const QUERY = "c";

export type ConfigurationEntry = { volumeForId: number, random: number };

export const hasConfiguration = () => {
  const queryparams = new URLSearchParams(window.location.search);
  return queryparams.has(QUERY);
}

export const getConfiguration = () => {
  const queryparams = new URLSearchParams(window.location.search);
  const configuration = queryparams.get(QUERY);

  if (!configuration) {
    return [];
  }

  try {
    return JSON.parse(atob(configuration));
  } catch (e) {
    return [];
  }
}

export const generateConfiguration = (sounds: Category[]) => {
  const configuration: ConfigurationEntry[] = [];
  sounds.forEach(category => {
    category.sounds.forEach(sound => {
      if (sound.volume !== 0) {
        const newConfiguration: any = {};
        newConfiguration[`${sound.id}`] = sound.volume;
        if (sound.isRandom) {
          newConfiguration.random = sound.intervalValue;
        }
        configuration.push(newConfiguration);
      }
    });
  });
  return btoa(JSON.stringify(configuration));
}

export const generateRandomConfiguration = (sounds: Category[]) => {
  const configuration: ConfigurationEntry[] = [];
  sounds.forEach(category => {
    category.sounds.forEach(sound => {
      if (Math.random() > 0.8) {
        const newConfiguration: any = {};
        newConfiguration[`${sound.id}`] = Math.floor(Math.random() * 100) / 100;

        // Randomize the random value
        if (Math.random() > 0.7) {
          // Extract a random value from the randomIntervals array
          const randomInterval = randomIntervals[Math.floor(Math.random() * randomIntervals.length)];
          newConfiguration.random = randomInterval.value;
        }

        configuration.push(newConfiguration);
      }
    });
  });
  return btoa(JSON.stringify(configuration));
}